/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { extend } from 'umi-request';
import { notification } from 'antd';
import { toUpper } from 'lodash';
import _omit from 'lodash/omit';
import type { RequestOptionsInit } from 'umi-request';
import { NSToken } from '@/services/identity';
import { WdiModalAppResult } from '@/components/WdiControls';

const codeMessage: Record<number, string> = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

const LOGIN_URL = "/user/login";

/**
 * 异常处理程序
 */
const errorHandler = (error: { response: Response }): Response => {
  const { response } = error;
  if (response && response.status) {
    if (response.status) {// !== 401
      const errorText = codeMessage[response.status] || response.statusText;
      const { status } = response;
      notification.error({
        message: `请求错误 ${status}`,
        description: errorText,
      });
    }
  } else if (!response) {
    notification.error({
      description: '您的网络发生异常，无法连接服务器',
      message: '网络异常',
    });
  }
  return response;
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  errorHandler, // 默认错误处理
  prefix: API_URL
});

/**
 * request请求拦截
 * */
request.use(async (ctx, next) => {
  const { options } = ctx.req;
  // 1. 校验token是否过期
  if (!NSToken.validate()) {
    const tokenResult = await NSToken.refresh();
    if (tokenResult.status === ResponseStatus.SUCCESS) {
      // Token 获取成功
    } else {
      notification.error({
        description: '用户认证失败, 请重新登录',
        message: '认证失败',
      });
      location.href = LOGIN_URL;
      return;
    }
  }
  const accessToken = NSToken.accessToken();
  const bu = NSToken.getBu();
  if (!bu) {
    notification.error({
      description: '请求bu缺失，刷新页面再试',
      message: '请求失败',
    });
    console.error("无效的bu请求", ctx.req.url);
    return;
  }
  const headers = Object.assign({}, options.headers, {
    Authorization: `Bearer ${accessToken}`,
    'BU': bu,
    'wdi-version': VERSION
  });
  options.headers = headers;
  return next();
})

/**
 * request请求相应拦截器, 认证失败跳转到登录页面
 */
request.interceptors.response.use(async (response, options): Promise<Response> => {
  const { status } = response.clone();
  if (status === 401) {
    // Unauthorized
    // Token 被篡改之后需要重新登陆
    // NSToken.clearToken();
    location.href = LOGIN_URL;
  }
  if (status === 402) {
    // Unauthorized
    // NSToken.clearToken();
    location.href = LOGIN_URL;
  }
  return response;
}, { global: false });

export async function retryRequest<T>(url: string, options: RequestOptionsInit): Promise<T> {
  let needRetry;
  let res: any, retryNo = "";
  const retry = (r: API.RetryEntity<API.ResponseResult<T>>) => r.progress != 100;
  let noTryResponse = false;

  do {
    options.headers = Object.assign({}, options.headers, {'Retry-No': retryNo});
    res = await request<API.RetryEntity<API.ResponseResult<T>>>(url, options).then(response => response);
    needRetry = retry(res);
    if (res.progress != undefined) {
      if (needRetry) {
        await new Promise(resolve => setTimeout(resolve, 3000));
        retryNo = res.retryNo;
      }
    } else {
      needRetry = false;
      noTryResponse = true;
    }
  } while (needRetry)

  return new Promise((resolve, reject) => {
    if (noTryResponse) {
      res.code = 201;
      res.message = '重试接口配置异常'
    }
    resolve(res);
  });
}

/**
 * 没有bu和token参数
 * */
const defaultRequest = extend({
  errorHandler,
  prefix: API_URL
});

/**
 * 只有bu没有Token
 * */
const nonTokenRequest = extend({
  errorHandler,
  prefix: API_URL
});

/**
 * 只有Token没有bu参数
 * */
const nonBuRequest = extend({
  errorHandler,
  prefix: API_URL
});

/**
 * nonBuRequest请求拦截
 * */
nonBuRequest.use(async (ctx, next) => {
  const { options } = ctx.req;
  // 1. 校验token是否过期
  if (!NSToken.validate()) {
    const tokenResult = await NSToken.refresh();
    if (tokenResult.status === ResponseStatus.SUCCESS) {
      // Token 获取成功
    } else {
      notification.error({
        description: '用户认证失败, 请重新登录',
        message: '认证失败',
      });
      location.href = LOGIN_URL;
      return;
    }
  }
  const accessToken = NSToken.accessToken();
  const headers = Object.assign({}, options.headers, {
    Authorization: `Bearer ${accessToken}`,
    'wdi-version': VERSION
  });
  options.headers = headers;
  return next();
});

/**
 * nonBuRequest请求相应拦截器, 认证失败跳转到登录页面
 */
nonBuRequest.interceptors.response.use(async (response, options): Promise<Response> => {
  const { status } = response.clone();
  if (status === 401) {
    // Unauthorized
    // Token 被篡改之后需要重新登陆
    // NSToken.clearToken();
    location.href = LOGIN_URL;
  }
  if (status === 402) {
    // Unauthorized
    // NSToken.clearToken();
    location.href = LOGIN_URL;
  }
  return response;
}, { global: false });

const ResponseStatus = {
  SUCCESS: 200,
  UNKNOWN: 201,
}

/**检查返回数据,responseResult.Status == ResponseStatus.SUCCESS 返回 True */
function responseIsSuccess<T>(responseResult: API.ResponseResult<T> | undefined | WdiModalAppResult) {
  return responseResult && responseResult.code == ResponseStatus.SUCCESS;
}

/**异步延时函数 await delay(1000) */
const delay = (ms: number) => new Promise((resolve, reject) => setTimeout(resolve, ms));

const formatter = (option: any): RequestOptionsInit => {
  let { ...options } = option;

  if (toUpper(options.method) === 'POST'
    || toUpper(options.method) === 'DELETE'
    || toUpper(options.method) === 'PUT') {
    // params --> 会被放到body的请求参数，POST／PUT／DELETE 方法
    options.data = options.body
  } else {
    // params --> 会被拼接到url上的的请求参数，GET方法
    options.params = options.body
  }
  if (options.body) {
    options = _omit(options, 'body')
  }
  return options;
}

export { request, defaultRequest, nonBuRequest, nonTokenRequest, ResponseStatus, responseIsSuccess, delay, formatter };
